import * as React from "react";
import { Link } from "gatsby";
import "./index.scss";

interface SitemapPropsType {
  products?: any;
  alldata?: any;
  settings?: any;
}

const Sitemap: React.FC<SitemapPropsType> = ({
  products,
  alldata,
  settings,
}) => {
  return (
    <section className="sitemap">
      <div className="container">
        <h3>{settings?.group1}</h3>
        <ul>
          {alldata
            ?.filter((e: any) => e?.type === "page")
            .map((e: any, i: any) => {
              return (
                <li key={i}>
                  <Link to={`/${e?.slug !== "/" ? e?.slug : ""}`} key={i}>
                    {e?.title.replace(/<\/?[^>]+(>|$)/g, "")}
                  </Link>
                </li>
              );
            })}
        </ul>
        <h3>{settings?.group2}</h3>
        <ul>
          {products.map((e: any, i: any) => {
            return (
              <li key={i}>
                <Link
                  to={`/${`Best ${e?.keyword} in ${e?.city}`
                    .toLowerCase()
                    .replace(/[^a-zA-Z0-9 ]/g, "")
                    .replace(/ /g, "-")}`}
                  key={i}
                >
                  {e?.keyword}
                </Link>
              </li>
            );
          })}
        </ul>
        <h3>{settings?.group3}</h3>
        <ul>
          {alldata
            ?.filter((e: any) => e?.type === "article")
            .map((e: any, i: any) => {
              return (
                <li key={i}>
                  <Link to={`/${e?.slug !== "/" ? e?.slug : ""}`} key={i}>
                    {e?.title}
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>
    </section>
  );
};

export default Sitemap;
